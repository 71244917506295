//
// ToTop
// --------------------------------------------------

.ui-to-top {
  position: fixed;
  right: $to-top-position-right;
  bottom: $to-top-position-bottom;
  color: $primary;
  box-shadow: $shadow-area-ambient;
  overflow: hidden;
  z-index: 1;
  transform: translateY(100px);


  &:focus{
    color: $primary;
    //background: $to-top-hover-background;
    //border-color: $to-top-hover-background;
  }

  &:hover {
    color: $to-top-hover-color;
    background: $to-top-hover-background;
    border-color: $to-top-hover-background;
  }

  &.active{
    transform: translateY(0);
  }
  &.icon-circle{
    line-height: 31px;
    width: 35px;
    height: 35px;
  }
}

.mobile .ui-to-top,
.tablet .ui-to-top {
  display: none !important;
}

@include media-breakpoint-up(sm) {
  .ui-to-top {
    right: $to-top-position-xs-right;
    bottom: $to-top-position-xs-bottom;
  }
}


