//
// RD Navbar Default Aside Left
// --------------------------------------------------

%rd-navbar-default-mobile-menu{
  .rd-navbar-nav {
    padding: 0;
    margin-top: $rd-navbar-mobile-menu-list-offset-top;
    >li:last-child{
      margin-bottom: 10px;
    }
    li {
      float: none;
      + li {
        margin-top: $rd-navbar-mobile-menu-list-item-offset-top;
      }
      a {
        @extend %rd-navbar-default-link-font !optional;
        cursor: pointer;
        display: block;
        padding: $rd-navbar-mobile-menu-list-item-link-padding;
        span{
          vertical-align: middle;
        }
        &.btn{
          display: inline-block;
        }
      }
      &.opened {
        > .rd-navbar-submenu-toggle:before {
          content: $rd-navbar-mobile-menu-list-item-arrow-active-content;
        }
      }

      .resp-tab-active {
        > .resp-arrow:before {
          content: $rd-navbar-mobile-menu-list-item-arrow-active-content;
        }
      }
      &:hover {
        > a {
          color: $primary;
        }
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-dark-mobile-menu-list-item-hover-toggle-color;
        }
      }
      &.opened, &.active {
        > a {
          color: $primary;
        }
        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-dark-mobile-menu-list-item-hover-toggle-color;
        }
      }
      &.active > a.btn-primary, &.focus > a.btn-primary, > a.btn-primary:hover {
        color: $white;
      }
    }
  }

  .rd-navbar-submenu {
    position: relative;
  }

  .rd-navbar-submenu-toggle, .responsive-tabs .resp-accordion .resp-arrow {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: $rd-navbar-mobile-menu-list-item-arrow-width;
    font-size: $rd-navbar-mobile-menu-list-item-arrow-size;
    line-height: $rd-navbar-mobile-menu-list-item-arrow-line-height;
    font-family: $rd-navbar-mobile-menu-list-item-arrow-family;
    transition: .3s all ease;
    &:before {
      content: $rd-navbar-mobile-menu-list-item-arrow-content;
      display: block;
      vertical-align: middle;
    }
  }

}

//
// Dark Theme
// --------------------------------------------------

%rd-navbar-default-mobile-menu-dark{

  .rd-navbar-nav {
    li {
      a, .responsive-tabs .resp-accordion {
        color: $rd-navbar-dark-mobile-menu-list-item-link-color;
      }


    }
  }
  .rd-navbar-submenu-toggle {
    color: $rd-navbar-dark-mobile-menu-submenu-toggle-color;
  }
}
