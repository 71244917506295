//
// Pricing and Plans
// --------------------------------------------------

// Box Planning
// -------------------------

.box-planning {
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 11%;

  &-label {
    display: none;
    z-index: 2;
    color: $white;

    span {
      position: absolute;
    }
  }

  // Reset default
  h1, .h1 {
    margin-bottom: 0;
    margin-top: 0;
  }

  h2, .h2 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .small {
    margin-top: 0;
    font-size: 13px;
  }

  .btn {
    margin-top: 20px;
  }

  .plan-price{
    sup{
      font-size: 35%;
      top: -1.2em;
      &.big{
        font-size: 60%;
        top: -0.6em;
      }
    }
    sub{
      font-size: 35%;
      bottom: 0;
    }
    font-weight: 400;
  }

  .list, .list-separated {
    span + span {
      margin-left: 6px;
    }
  }

  .box-planning-body {
    padding: 11% 8% 12% 8%;
    border: 1px solid $gray-200;
    border-top: none;
  }

  &.active {
    .box-planning-label {
      display: block;
    }
  }
}


// Box planning type 3,4
// -------------------------

.box-planning-type-3, .box-planning-type-4 {
  max-width: 295px;
  padding: 0;

  .box-planning-header {
    padding: 11%;

    h2, .h2 {
      margin-bottom: 0;
    }
    h3, .h3 {
      margin-top: 5px;
      margin-bottom: 7px;
    }
  }
  p.big{
    font-size: 17px;
  }
  .btn {
    margin-top: 37px;
  }

  .btn-default {
    @include button-variant-custom($gray-700, $white, $gray-700, $white, $primary, $primary);
    border-width: 1px;
  }
  &.active{
    .btn-default {
      @include button-variant-custom($white, $primary, $primary, $white, darken($primary, 10%), darken($primary, 10%));
      border-width: 1px;
    }
  }
}
