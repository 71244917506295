//
// Quotes
// --------------------------------------------------


// Base Styles
// --------------------------------------------------

.quote {
  border: none;
  margin: 0;
  padding: 0;
  font-size: inherit;
}

// Quote Classic
// -------------------------

.quote-classic {
  text-align: left;
  position: relative;

  q {
    quotes: none;
  }

  .quote-body {
    padding: $quote-classic-body-padding;
  }

  .quote-meta {
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .quote-desc {
    font-size: $quote-classic-desc-size;
    font-weight: $quote-classic-desc-weight;
  }

  .quote-author {
    margin-bottom: 4px;
  }

  .quote-desc {
    margin-top: 4px;
  }

  > *{
    position: relative;
    z-index: 1;
  }

  &:before {
    position: absolute;
    top: -8px;
    left: -12px;
    z-index: 0;
    font-family: $quote-braces-font;
    font-size: $quote-braces-size;
    line-height: $quote-braces-line-height;
    color: $quote-braces-color;
    content: $quote-braces-icon;
    transform: scaleX(-1);
  }
}

// Quote Classic Boxed v2
// -------------------------

.quote-classic-boxed-2 {
  .quote-body {
    padding: $quote-classic-boxed-2-body-padding;
    background: $quote-classic-boxed-2-body-background;
    border-radius: $quote-classic-boxed-2-body-border-radius;
    box-shadow: $quote-classic-boxed-2-body-box-shadow;
  }

  .quote-meta {
    margin-left: 25px;
    position: relative;
    z-index: 3;
  }

  .quote-author {
    margin-top: 20px;
  }

  &:before {
    top: 50px;
    right: 15px;
    left: auto;
    z-index: 2;
    font-family: $quote-braces-font;
    font-size: $quote-braces-size;
    line-height: $quote-braces-line-height;
    color: $quote-classic-boxed-2-braces-icon-color;
    content: $quote-braces-icon;
    transform: none;
  }
  a:hover{
    .text-darker{
      transition: 0.35s all ease;
      color: $primary;
    }
  }
  .h6{
    letter-spacing: 0;
    font-size: 17px;
    font-weight: 700;
  }
}
.ie-edge{
  .quote-classic-boxed-2 {
    .quote-body {
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
    }
  }
}

// Quote Slider v2
// -------------------------

.quote-slider-2 {

  .quote-body {
    margin-top: 24px;
  }

  .quote-author {
    margin-top: 24px;
    margin-bottom: 0;
    color: $primary;
    font-size: 17px;
    line-height: 25px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .quote-desc {
    margin-top: 4px;
    font-size: 12px;
    color: rgba(255,255,255, .5);
  }
}

// Quote Simple Slider
// -------------------------

.quote-simple-2 {
  .quote-body {
    margin-bottom: 0;
  }

  .quote-author {
    margin-top: 12px;
  }
}


