// Unit Utilities
@mixin unit-spacing($spacing-x, $spacing-y: $spacing-x) {
  margin-bottom: -$spacing-y;
  margin-left: -$spacing-x;

  > * {
    margin-bottom: $spacing-y;
    margin-left: $spacing-x;
  }
}

@mixin unit-responsive($spacing-x, $spacing-y: $spacing-x) {
  .unit {
    display: flex;
    flex: 0 1 100%;
    flex-direction: column;
    @include unit-spacing($spacing-x, $spacing-y);
  }

  .unit:empty {
    margin-bottom: 0;
    margin-left: 0;
  }

  .unit-body {
    flex: 0 1 auto;
  }

  .unit-left,
  .unit-right {
    flex: 0 0 auto;
    max-width: 100%;
  }
}
