//
// RD Navbar Default Panel
// --------------------------------------------------

%rd-navbar-default-panel{
  .rd-navbar-panel{
    background: $white;
    box-shadow: $shadow-area-xs;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 16;
  }

  &, .rd-navbar-panel{
    height: $rd-navbar-fixed-height;
  }

  .rd-navbar-toggle {
    position: fixed;
    z-index: 17;
    top: 4px;
    left: 4px;
    @include toggle-lines-to-arrow(48px, 24px, $rd-navbar-panel-toggle-color);
  }

  .rd-navbar-cart {
    position: fixed;
    z-index: 17;
    top: 4px;
    right: 4px;
    @include toggle-icons-via-rotation(48px, 24px, $rd-navbar-panel-cart-icon-color, $rd-navbar-panel-cart-icon-state-before, $rd-navbar-panel-cart-icon-state-after);
  }

  @include media-breakpoint-up(sm) {
    .rd-navbar-brand {
      display: block;
      position: fixed;
      top: 10px;
      left: 50%;
      width: auto;
    }
    &.active {
    }
  }
}

//
// Dark Theme
// --------------------------------------------------

%rd-navbar-default-panel-dark{
  .rd-navbar-panel {
    background: $rd-navbar-dark-panel-background;
    box-shadow: nth($shadow-area-sm, 1) nth($shadow-area-sm, 2) nth($shadow-area-sm, 3) nth($shadow-area-sm, 4) rgba($gray-800, .3);
  }

  .rd-navbar-cart {
    color: $rd-navbar-dark-cart-color;
  }
}
