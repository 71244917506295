//
// RD Navbar Default First level of Menu
// --------------------------------------------------

%rd-navbar-default-menu-first-lvl{
  .rd-navbar-nav {
    @include clearfix;
    > li {
      float: left;
      position: relative;
      > a {
        @extend %rd-navbar-default-link-font !optional;
        position: relative;
        display: inline-block;
        vertical-align: middle;
      }

      &.active > a, &.focus > a, > a:hover {
        color: $primary;
      }
      &.active > a.btn-primary, &.focus > a.btn-primary, > a.btn-primary:hover {
        color: $white;
      }

      &.rd-navbar--has-megamenu {
        position: static;
      }

      + li {
        margin-left: 22px;
      }
      .btn-sm{
        margin-top: -3px;
        margin-left: 10px;
        padding: 7px 12px;
        font-size: 10px;
        line-height: 12px;
        .icon{
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
}

//
// Dark Theme
// --------------------------------------------------

%rd-navbar-default-menu-first-lvl-dark {
  .rd-navbar-nav {
    > li {
      a {
        color: $rd-navbar-dark-menu-first-lvl-list-item-link-color;
      }
    }
  }
}
